import { Typography } from '@remarkable/ark-web';
import { CheckCircle } from 'phosphor-react';

import { ButtonClicked } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { ComponentLocations } from 'src/analytics/trackingTypes';
import {
  useAccountInfo,
  useRequestMultiFactorAuth,
  useResetMultiFactorAuth,
} from 'src/api/queries';
import { Button, NotificationBox, Tooltip } from 'src/components';

import { DisableMfaModal } from './DisableMfaModal';

export const EditMultiFactorAuth = () => {
  const accountInfo = useAccountInfo();
  const requestMfa = useRequestMultiFactorAuth();
  const resetMfa = useResetMultiFactorAuth();

  if (accountInfo.isPending) return null;

  const hasMfa =
    accountInfo.data?.mfaEnrolled === true ||
    accountInfo.data?.mfa_enrolled === true;

  const handleRequestMfaClick = () => {
    requestMfa.mutate();
    tracker.trackEvent(
      new ButtonClicked({
        component_location: ComponentLocations.ACCOUNT.SECURITY_CARD,
        text: 'Enable MFA',
        action: 'enable MFA',
      })
    );
  };

  return (
    <>
      <div>
        <div className="flex justify-between gap-16">
          <Typography variant="heading-subtle-lg" as="h3">
            {hasMfa || accountInfo.isError ? 'Multi' : 'Add multi'}-factor
            authentication
            {hasMfa && (
              <Tooltip title="MFA is enabled">
                <CheckCircle
                  weight="fill"
                  size={24}
                  className="ml-8 mt-[-7px] inline-block text-feedback-green-500"
                />
              </Tooltip>
            )}
          </Typography>
        </div>

        <div className="flex flex-col gap-16 lm:flex-row">
          <Typography variant="body-md-regular">
            Keep your account secure with an extra verification step. With MFA
            you&apos;ll be asked to provide a second authentication factor when
            logging in.
          </Typography>

          {!requestMfa.isSuccess &&
            !accountInfo.isError &&
            (hasMfa ? (
              <DisableMfaModal
                trigger={
                  <Button
                    variant="tertiary"
                    size="medium"
                    className="w-full self-start whitespace-nowrap ls:w-fit"
                    data-cy="reset-mfa-button"
                  >
                    <span className="whitespace-nowrap">Disable MFA</span>
                  </Button>
                }
              />
            ) : (
              <Button
                variant="tertiary"
                size="medium"
                className="w-full self-start whitespace-nowrap ls:w-fit"
                data-cy="enroll-mfa-button"
                disabled={requestMfa.isPending}
                loading={requestMfa.isPending}
                onClick={handleRequestMfaClick}
              >
                <span className="whitespace-nowrap">Enable MFA</span>
              </Button>
            ))}
        </div>
      </div>
      {requestMfa.isSuccess && (
        <NotificationBox
          variant="success"
          title="Email sent"
          data-cy="enroll-mfa-success-notification"
        >
          <Typography variant="body-md-regular">
            We sent you an email with instructions to activate your multi-factor
            authentication.
          </Typography>
        </NotificationBox>
      )}
      {(requestMfa.isError || resetMfa.isError) && (
        <NotificationBox
          variant="error"
          title="Something went wrong"
          data-cy="update-mfa-failure-notification"
        >
          <Typography variant="body-sm-regular" as="p">
            Something went wrong while{' '}
            {requestMfa.isError ? 'enabling' : 'disabling'} multi-factor
            authentication. Please try again later.
          </Typography>
        </NotificationBox>
      )}
      {accountInfo.isError && (
        <NotificationBox variant="error" title="Something went wrong">
          We couldn&apos;t load your multi-factor authentication status. Please
          try again later.
        </NotificationBox>
      )}
    </>
  );
};
