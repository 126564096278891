import { ReactNode } from 'react';

import { Typography } from '@remarkable/ark-web';

import {
  SubscriptionCancellationFlowCompleted,
  SubscriptionCancellationFlowExited,
} from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { ComponentLocations } from 'src/analytics/trackingTypes';
import { useCancelSubscription, useSubscriptionMembers } from 'src/api/queries';
import { Button, Modal, NotificationBox } from 'src/components';

export const CancelSubscriptionModal = ({
  trigger,
}: {
  trigger: ReactNode;
}) => {
  const cancelSubscription = useCancelSubscription();
  const members = useSubscriptionMembers();

  const memberCount =
    members.data?.filter((member) => member.email).length ?? 0;

  const onClose = () => {
    tracker.trackEvent(
      new SubscriptionCancellationFlowExited({
        component_location: ComponentLocations.MANAGE_CONNECT.SUBSCRIPTION_PAGE,
        total_steps: 1,
        step_number: 1,
      })
    );
  };

  return (
    <Modal.Root>
      <Modal.Trigger asChild>{trigger}</Modal.Trigger>

      <Modal.Content title={'Cancel subscription?'}>
        <Typography variant="body-md-regular" className="mb-16">
          Are you sure you want to cancel the subscription
          {memberCount > 1 && <b> for all {memberCount} members</b>}?
        </Typography>

        <Typography variant="body-md-regular" className="mb-16">
          At the end on the billing period, members will lose access to all
          features, such as note-taking in the mobile and desktop apps, and
          unlimited cloud storage and sync.
        </Typography>

        <Typography variant="body-md-regular" className="mb-16">
          Files already stored in the cloud will still be accessible for team
          members.
        </Typography>

        {cancelSubscription.isError && (
          <NotificationBox
            variant="error"
            title="Something went wrong"
            className="mt-16"
          >
            Please try again or contact support if the problem persists.
          </NotificationBox>
        )}

        <div className="mt-32 flex flex-col justify-center gap-16 ls:flex-row">
          <Modal.Close>
            <Button
              variant="primary"
              size="medium"
              className="w-full xs:w-fit"
              onClick={onClose}
              analytics={{
                text: 'Keep subscription',
                location: ComponentLocations.MANAGE_CONNECT.SUBSCRIPTION_PAGE,
                action: 'keep subscription (close modal)',
              }}
            >
              Keep subscription
            </Button>
          </Modal.Close>

          <Button
            variant="secondary"
            size="medium"
            className="w-full xs:w-fit"
            loading={cancelSubscription.isPending}
            onClick={() => {
              tracker.trackEvent(
                new SubscriptionCancellationFlowCompleted({
                  component_location:
                    ComponentLocations.MANAGE_CONNECT.SUBSCRIPTION_PAGE,
                  total_steps: 1,
                  step_number: 1,
                })
              );
              cancelSubscription.mutate(undefined);
            }}
            analytics={{
              text: 'Cancel subscription',
              location: ComponentLocations.MANAGE_CONNECT.SUBSCRIPTION_PAGE,
              action: 'cancel subscription',
            }}
          >
            Cancel subscription
          </Button>
        </div>
      </Modal.Content>
    </Modal.Root>
  );
};
