import { SignOut } from '@phosphor-icons/react';
import { Typography } from '@remarkable/ark-web';
import { getRouteApi } from '@tanstack/react-router';
import { ArrowCounterClockwise, ArrowRight, X } from 'phosphor-react';
import toast from 'react-hot-toast';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { useCurrentMember, useSubscriptionSuspense } from 'src/api/queries';
import { subscriptionHelpers } from 'src/utils/subscriptionHelpers';
import { URLS } from 'src/utils/urls/urls';

import { Button, Link, NotificationBox, Sheet } from '../../../../components';
import { AbortCancelSubscriptionModal } from './AbortCancelSubscriptionModal';
import { CancelSubscriptionButton } from './CancelSubscriptionButton';
import { CancelSubscriptionModal } from './CancelSubscriptionModal';
import { ConnectSeatsDetails } from './ConnectPlanSeatsDetails';
import { ConnectSimpleDetails } from './ConnectPlanSimpleDetails';
import { FreeConnectDetails } from './FreeConnectDetails';
import {
  LeaveSubscriptionModal,
  useLeaveSubscriptionModal,
} from './LeaveSubscriptionModal';
import { NoSubscriptionDetails } from './NoSubscriptionCard';

const routeApi = getRouteApi('/_auth/_layout/subscriptions/plan');

export const SubscriptionCard = () => {
  const subscription = useSubscriptionSuspense();
  const leaveSubscriptionModal = useLeaveSubscriptionModal();
  const member = useCurrentMember();
  const search = routeApi.useSearch();
  const navigate = routeApi.useNavigate();

  const shouldSeeLeaveButton =
    member.data?.access.canRemoveUser &&
    !subscription.data?.access.canSeeMembers;
  const shouldSeePaymentButton =
    subscriptionHelpers.isActive(subscription.data) &&
    !subscriptionHelpers.isFreeConnect(subscription.data);

  const isCanceled = subscriptionHelpers.isCancelled(subscription.data);

  if (search.cancel_subscription === 'confirmation') {
    toast.success('Subscription cancelled', {
      id: 'subscription-cancelled-toast',
    });
    void navigate({ search: { cancel_subscription: undefined } });
  }

  return (
    <Sheet className="gap-32" data-cy="subscription-card">
      <SubscriptionDetails />
      {isCanceled && (
        <NotificationBox variant="info" title="Help us get better">
          <Typography variant="body-sm-regular" as="p">
            Help us understand why Connect wasn&apos;t for you by answering{' '}
            <Link inline to={URLS.CANCELLED_SUBSCRIPTION_SURVEY}>
              this survey
            </Link>
          </Typography>
        </NotificationBox>
      )}
      <div className="flex flex-wrap gap-24">
        {shouldSeePaymentButton && (
          <Button
            as="a"
            to={URLS.SUBSCRIPTION_PAYMENT}
            variant="primary"
            size="large"
            className="w-full ls:w-fit"
            data-cy="edit-payment-details-btn"
          >
            <span>Edit payment details</span>
            <ArrowRight />
          </Button>
        )}

        {shouldSeeLeaveButton && (
          <LeaveSubscriptionModal
            trigger={
              <Button
                variant="secondary"
                onClick={leaveSubscriptionModal.open}
                size="large"
                className="w-full ls:w-fit"
                analytics={{
                  text: 'Leave subscription',
                  location: ComponentLocations.MANAGE_CONNECT.SUBSCRIPTION_PAGE,
                  action: 'open leave subscription modal',
                }}
              >
                <SignOut /> <span>Leave subscription</span>
              </Button>
            }
          ></LeaveSubscriptionModal>
        )}

        {subscription.data?.access.canCancel &&
          !isCanceled &&
          (subscriptionHelpers.isManagementTypeSeats(subscription.data) ? (
            <CancelSubscriptionModal
              trigger={
                <Button
                  variant="tertiary-neutral"
                  size="large"
                  className="w-full ls:w-fit"
                  analytics={{
                    text: 'Cancel subscription',
                    location:
                      ComponentLocations.MANAGE_CONNECT.SUBSCRIPTION_PAGE,
                    action: 'open cancel subscription modal',
                  }}
                >
                  <X />
                  <span>Cancel subscription</span>
                </Button>
              }
            />
          ) : (
            <CancelSubscriptionButton />
          ))}

        {subscription.data?.access.canReactivate && isCanceled && (
          <AbortCancelSubscriptionModal
            trigger={
              <Button
                variant="primary-neutral"
                size="large"
                analytics={{
                  text: 'Undo cancelation',
                  location: ComponentLocations.MANAGE_CONNECT.SUBSCRIPTION_PAGE,
                  action: 'open undo cancelation modal',
                }}
              >
                <ArrowCounterClockwise />
                <span>Undo cancelation</span>
              </Button>
            }
          />
        )}
      </div>
    </Sheet>
  );
};

const SubscriptionDetails = () => {
  const subscriptions = useSubscriptionSuspense();

  if (subscriptions.isError) {
    return (
      <NotificationBox variant="error" title="Something went wrong">
        Error loading subscriptions
      </NotificationBox>
    );
  }

  if (subscriptionHelpers.isStripe(subscriptions.data)) {
    if (subscriptions.data.managementType === 'seats') {
      return <ConnectSeatsDetails />;
    }

    return <ConnectSimpleDetails />;
  }

  if (subscriptionHelpers.isFreeConnect(subscriptions.data)) {
    return <FreeConnectDetails />;
  }

  return <NoSubscriptionDetails />;
};
