import { Typography } from '@remarkable/ark-web';
import { ReactNode } from '@tanstack/react-router';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { useAbortCancelSubscription } from 'src/api/queries';
import { Button, Modal, NotificationBox } from 'src/components';

export const AbortCancelSubscriptionModal = ({
  trigger,
}: {
  trigger: ReactNode;
}) => {
  const abortCancelSubscription = useAbortCancelSubscription();

  return (
    <Modal.Root>
      <Modal.Trigger asChild>{trigger}</Modal.Trigger>

      <Modal.Content title={'Undo cancellation?'}>
        <Typography variant="body-md-regular" className="mb-16">
          Undo your cancelation if you would like to keep subscription features
          for you and your team.
        </Typography>

        {abortCancelSubscription.isError && (
          <NotificationBox
            variant="error"
            title="Something went wrong"
            className="mt-16"
          >
            Please try again or contact support if the problem persists.
          </NotificationBox>
        )}

        <div className="mt-32 flex flex-col justify-center gap-16 ls:flex-row">
          <Modal.Close asChild>
            <Button
              variant="secondary"
              size="medium"
              className="w-full xs:w-fit"
              analytics={{
                text: 'Close',
                location: ComponentLocations.MANAGE_CONNECT.SUBSCRIPTION_PAGE,
                action: 'close undo cancelation modal',
              }}
            >
              Close
            </Button>
          </Modal.Close>

          <Button
            size="medium"
            className="w-full xs:w-fit"
            loading={abortCancelSubscription.isPending}
            onClick={() => {
              abortCancelSubscription.mutate(undefined);
            }}
            analytics={{
              text: 'Undo cancelation',
              location: ComponentLocations.MANAGE_CONNECT.SUBSCRIPTION_PAGE,
              action: 'undo cancelation',
            }}
          >
            Undo cancelation
          </Button>
        </div>
      </Modal.Content>
    </Modal.Root>
  );
};
