import { Typography } from '@remarkable/ark-web';
import { ReactNode } from '@tanstack/react-router';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { useResetMultiFactorAuth } from 'src/api/queries';
import { Button, Modal, NotificationBox } from 'src/components';

export const DisableMfaModal = ({ trigger }: { trigger: ReactNode }) => {
  const resetMfa = useResetMultiFactorAuth();

  return (
    <Modal.Root>
      <Modal.Trigger asChild>{trigger}</Modal.Trigger>
      <Modal.Content title="Are you sure you want to disable multi-factor authentication?">
        <Typography variant="body-md-regular" className="mb-16">
          Turning off multi-factor authentication increases the risk of
          unauthorized access and security breaches.
        </Typography>

        {resetMfa.isError && (
          <NotificationBox
            variant="error"
            className="mb-16"
            title="Multi-factor authentication couldn't be turned off"
          >
            Please try again or contact support for assistance.
          </NotificationBox>
        )}
        <div className="mt-32 flex flex-col justify-center gap-16 ls:flex-row">
          <Modal.Close asChild>
            <Button
              variant="secondary"
              size="medium"
              className="w-full xs:w-fit"
            >
              No, cancel
            </Button>
          </Modal.Close>

          <Button
            size="medium"
            className="w-full xs:w-fit"
            onClick={() => resetMfa.mutate(undefined)}
            loading={resetMfa.isPending}
            analytics={{
              location: ComponentLocations.ACCOUNT.SECURITY_CARD,
              text: 'Disable MFA',
              action: 'disable MFA',
            }}
          >
            Yes, disable
          </Button>
        </div>
      </Modal.Content>
    </Modal.Root>
  );
};
