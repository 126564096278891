import { Typography } from '@remarkable/ark-web';
import { ReactNode } from '@tanstack/react-router';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { useCurrentMember, useLeaveSubscription } from 'src/api/queries';
import { Button, Modal, NotificationBox } from 'src/components';
import { createModalHook } from 'src/utils/useModal';

export const useLeaveSubscriptionModal = createModalHook('LeaveSubscription');

export const LeaveSubscriptionModal = ({ trigger }: { trigger: ReactNode }) => {
  const leaveSubscription = useLeaveSubscription();

  const currentMember = useCurrentMember();

  return (
    <Modal.Root>
      <Modal.Trigger asChild>{trigger}</Modal.Trigger>
      <Modal.Content title={'Leave subscription'}>
        <Typography variant="body-md-regular" className="mb-24">
          Are you sure you want to leave this subscription?
        </Typography>

        <Typography variant="body-md-regular">
          You&apos;ll lose access to all subscription features and benefits.
        </Typography>

        {leaveSubscription.isError && (
          <NotificationBox
            variant="error"
            title="Something went wrong"
            className="my-24"
          >
            Try again or contact support if the problem persists.
          </NotificationBox>
        )}

        <div className="mt-24 flex justify-center gap-16">
          <Button
            variant="secondary"
            loading={leaveSubscription.isPending}
            onClick={() => {
              if (!currentMember.data?.id) return;

              leaveSubscription.mutate({
                subscriptionRoleId: currentMember.data?.id,
              });
            }}
            disabled={!currentMember.data?.seat?.id}
            analytics={{
              text: 'Leave subscription',
              location: ComponentLocations.MANAGE_CONNECT.MAIN,
              action: 'leave subscription',
            }}
          >
            Leave subscription
          </Button>

          <Modal.Close>
            <Button
              analytics={{
                text: 'Stay',
                location: ComponentLocations.MANAGE_CONNECT.MAIN,
                action: 'stay (close modal)',
              }}
            >
              Stay
            </Button>
          </Modal.Close>
        </div>
      </Modal.Content>
    </Modal.Root>
  );
};
